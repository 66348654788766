// Images
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Contains images
.image {
    display: inline-block;
    width: 100%;
    margin: 0 0.5rem 1rem 0;

    // responsiveness
    img {
        width: 100%;
        max-width: 100%;
        height: auto;
        margin: 0;
        border: 0;
    }
}

// Linked image
a.image {
    @extend %link-reset;
}

// Large image size
.image--large {
    max-width: 250px;

    // "medium" size only
    @include breakpoint(medium only) {
        max-width: 350px;
    }

    // "large" size +
    @include breakpoint(large) {
        max-width: 400px;
    }
}

// Medium image size
.image--medium {
    max-width: 150px;

    // "medium" size only
    @include breakpoint(medium only) {
        max-width: 175px;
    }

    // "large" size +
    @include breakpoint(large) {
        max-width: 200px;
    }
}

// Small image size
.image--small {
    max-width: 75px;

    // "medium" size only
    @include breakpoint(medium only) {
        max-width: 90px;
    }

    // "large" size +
    @include breakpoint(large) {
        max-width: 120px;
    }
}

// Circle image
.image--circle {
    border-radius: 50%;
    overflow: hidden;
}

// Adds border
.image--bordered {
    border: 4px solid $black-15;

    // "medium" size only
    @include breakpoint(medium only) {
        border: 6px solid $black-15;
    }

    // "large" size +
    @include breakpoint(large) {
        border: 9px solid $black-15;
    }
}

// Adds frame
.image--framed {
    -webkit-box-shadow: $shadow;
    -moz-box-shadow:    $shadow;
    box-shadow:         $shadow;
    border: 4px solid $white;

    // "medium" size only
    @include breakpoint(medium only) {
        border: 6px solid $white;
    }

    // "large" size +
    @include breakpoint(large) {
        border: 9px solid $white;
    }
}