// Buttons
// ---------------------------------------------
// ---------------------------------------------
// ---------------------------------------------

// All Buttons
.button {
    text-decoration: none;
    font-weight: $bold;
    border: 2px solid $primary-color;
    line-height: 1.25;



    // Default Button
    &.default {
        background-color: $black-075;
        color: $primary-color;

        // Hover state
	    &:hover, &:focus, &:active {
            background-color: $black-15;
        }
    }

    // Default Button (Hollow)
    &.default.hollow {
        background-color: transparent;
        border-color: $black-15;

        // Hover state
	    &:hover, &:focus, &:active {
            background-color: $black-15;
            border-color: transparent;
        }
    }



    // Primary Button (Hollow)
    &.primary.hollow {

        // Hover state
	    &:hover, &:focus, &:active {
            background-color: $primary-darken;
            border-color: $primary-darken;
            color: $white;
        }

    }



    // Secondary Button (Hollow)
    &.secondary {
        border-color: transparent;

        // Hover state
	    &:hover, &:focus, &:active {
            border-color: $secondary-darken;
        }
    }

    // Secondary Button (Hollow)
    &.secondary.hollow {

        // Hover state
	    &:hover, &:focus, &:active {
            background-color: $secondary-darken;
            border-color: $secondary-darken;
            color: $white;
        }
        
    }



    // Raised Buttons (adds shadow, gradient)
    &.raised {
        -webkit-box-shadow: $shadow;
        -moz-box-shadow:    $shadow;
        box-shadow:         $shadow;
        overflow: hidden;
        position: relative;

        // Hover State
        &:hover, &:focus, &:active {
            -webkit-box-shadow: $shadow;
            -moz-box-shadow:    $shadow;
            box-shadow:         $shadow;
        }
    }
}