// Tables
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// table
table {

    // th cells
    th {
        font-weight: $bold;
    }

    // all cells
    th, td {
        border: 1px solid $black-15;
    }
}



// Google Map Embed
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Contains map iframe
.map-embed {
    margin: 0 0 1rem;
    width: 100%;
    padding-top: 56.25%;
    position: relative;

    // iframe
    iframe {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}


// Featured (50/50) Image Block
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Vertically centers 50/50 content
.content-50-50 {
    width: 100%;
    max-width: 100%;

	// "medium" size +
    @include breakpoint(medium) {
		display: flex;
		align-items: center;
		justify-content: center;
    }

    // Needed for bug fix
    > div {
        width: 100%;
        max-width: 100%;
    }
}

// Content on the left (on desktop)
.content-50-50--left {

    // "medium" size only
    @include breakpoint(medium only) {
        padding-right: 0.5rem;
    }

    // "large" size
    @include breakpoint(large) {
        padding-right: 1rem;
    }
}

// Content on the right (on desktop)
.content-50-50--right {

    // "medium" size only
    @include breakpoint(medium only) {
        padding-left: 0.5rem;
    }

    // "large" size
    @include breakpoint(large) {
        padding-left: 1rem;
    }
}



// Callouts
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Callout
.callout {
    background-color: transparent;
    padding: 1rem;

    // "medium" size
    @include breakpoint(medium) {
        padding: 1.5rem;
    }
}



// Staff Listing (Our Team)
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Staff listing
.staff-listing {
    margin-bottom: 1rem;
    box-shadow: $shadow;
    background-color: $white;
    
    // image
    .image {
        margin-bottom: 0;
    }

    // 
    *:last-child {
        margin-bottom: 0;
    }
}

// 
.staff-listing__body {
    padding: 1rem;

    // "medium" size
    @include breakpoint(medium) {
        padding: 1.5rem;
    }
}




// 
.alert {
    display: block;
    padding: 1rem 0;
    font-weight: inherit;
    border-bottom: 0;
    text-align: center;

    // Removes margin-bottom from last child
    *:last-child {
        margin-bottom: 0;
    }

    // Adds warning bg color
    &.warning {
        background-color: #fee685;
    }
}