// Icomoon font path
$icomoon-path: '../fonts/icomoon/';



// Icomoon font face
@font-face {
    font-family: 'icomoon';
    src:    url('#{$icomoon-path}icomoon.eot?bs5lic');
    src:    url('#{$icomoon-path}icomoon.eot?bs5lic#iefix') format('embedded-opentype'),
            url('#{$icomoon-path}icomoon.ttf?bs5lic') format('truetype'),
            url('#{$icomoon-path}icomoon.woff?bs5lic') format('woff'),
            url('#{$icomoon-path}icomoon.svg?bs5lic#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}



// All icons
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // Custom styles
  color: $primary-color;
  margin-right: 0.125rem;
}



// icon
.icon-email:before {
  content: "\e900";
}

// icon
.icon-document:before {
  content: "\e926";
}

// icon
.icon-location:before {
  content: "\e947";
}

// icon
.icon-clock:before {
  content: "\e94e";
}

// icon
.icon-calendar:before {
  content: "\e953";
}

// icon
.icon-printer:before {
  content: "\e954";
}

// icon
.icon-mobile:before {
  content: "\e958";
}

// icon
.icon-plus:before {
  content: "\ea0a";
}

// icon
.icon-minus:before {
  content: "\ea0b";
}

// icon
.icon-cross:before {
  content: "\ea0f";
}

// icon
.icon-checkmark:before {
  content: "\ea10";
}

// icon
.icon-arrow-up:before {
  content: "\ea3a";
}

// icon
.icon-arrow-right:before {
  content: "\ea3c";
}

// icon
.icon-arrow-down:before {
  content: "\ea3e";
}

// icon
.icon-arrow-left:before {
  content: "\ea40";
}

// icon
.icon-facebook:before {
  content: "\ea90";
}

// icon
.icon-instagram:before {
  content: "\ea92";
}

// icon
.icon-twitter:before {
  content: "\ea96";
}

// icon
.icon-youtube:before {
  content: "\ea9d";
}

// icon
.icon-linkedin:before {
  content: "\eaca";
}

// icon
.icon-phone:before {
  content: "\e942";
}

// icon
.icon-user:before {
  content: "\e971";
}

// icon
.icon-key:before {
  content: "\e98d";
}

// icon
.icon-lock:before {
  content: "\e98f";
}
