// Base
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// html
html {
    background-color: $medium-gray;
    font-size: 16px;

    // "medium" size only
    @include breakpoint(medium only) {
        font-size: 18px;
    }

    // "large" size +
    @include breakpoint(large) {
        font-size: 20px;
    }
}

// body
body {
    font-size: 1rem;
    position: relative;

    // "large" size +
    @include breakpoint(large) {
        max-width: $body-max-width;
        margin: 0 auto;
        -webkit-box-shadow: $shadow;
        -moz-box-shadow:    $shadow;
        box-shadow:         $shadow;
    }
}



// Typography
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// All headings (h1-h6)
h1, .h1, 
h2, .h2, 
h3, .h3, 
h4, .h4, 
h5, .h5, 
h6, .h6 {
    display: block;
    width: 100%;
    letter-spacing: 0;
    font-weight: $bold;

    // 
    &.image {
        margin-right: 0;
    }
}

// h1 only
h1, .h1 {
    font-family: $header-font-family;
    line-height: 1.2;
    text-transform: none;
    letter-spacing: 0;
}

// h2 only
h2, .h2 {
    font-family: $header-font-family;
    line-height: $header-lineheight;
    text-transform: none;
    letter-spacing: 0;
}

// h3 only
h3, .h3 {
    font-family: $header-font-family;
    line-height: $header-lineheight;
    text-transform: none;
    letter-spacing: 0;
}

// h4 only
h4, .h4 {
    font-family: $header-font-family;
    line-height: $header-lineheight;
    text-transform: none;
    letter-spacing: 0;
}

// h5 only
h5, .h5 {
    font-family: $body-font-family;
    line-height: $global-lineheight;
    text-transform: uppercase;
    letter-spacing: 1px;
}

// h6 only
h6, .h6 {
    font-family: $header-font-family;
    line-height: $global-lineheight;
    text-transform: none;
    letter-spacing: 0;
}

// Consistent element margins
blockquote, figure, table, small, .well {
	margin: 0 0 1rem;
}

// All anchors
a, .link {
    font-weight: $bold;
    border-bottom: 2px solid $primary-color;

    // Hover state
	&:hover, &:focus, &:active {
		transition: all 0.3s ease;
        background-color: $hover-darken-less;
	}
}

// Adds link style
.link {
    display: inline;
}

// Link/button hover transition
a:hover, button:hover {
	transition: all 0.3s ease;
}

// Hides empty <p> tags
p:empty {
	display: none;
}

// Bold text
b, strong {
    font-weight: $bold;
}

// Italic text
i {
    font-style: italic;
}

// Emphasis text (bold italic)
em {
    font-style: italic;
    font-weight: $bold;
}

// Lead text
.lead {
    font-size: 1.25rem;

    // "medium" size only
    @include breakpoint(medium only) {
        font-size: 1.33rem;
    }

    // "large" size +
    @include breakpoint(large) {
        font-size: $lead-font-size;
    }
}

// Small text, fine print, captions
small, .fine, caption, figcaption {
    font-size: $small-font-size;
    font-style: normal;
    display: block;
}

// Address
address {
    display: block;
	margin: 0 0 1rem;
    line-height: inherit;
    font-style: normal;
}

// blockquote
blockquote {
    font-style: italic;
}

// Cite
cite {
    font-family: $pt-sans;
    font-style: normal;
}

// Checkmark list
.checkmark-list {
    list-style: none;
    margin-left: 0;

    // li
    li {
        position: relative;
        padding-left: 1.5rem;
        //margin-bottom: 0.5rem;
    
        // Adds checkmark (Icomoon icon)
        &:before {
            position: absolute;
            top: 3px;
            left: 0;

            // Icomoon stuff
            font-family: 'icomoon' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            // Icomoon checkmark
            content: "\ea10";
        }
    }
}

// 
.unstyled-list {
    list-style: none;
    padding: 0;
    margin-left: 0;

    // 
    li {
        
    }
}

// horizontal rule
hr {
    width: $hr-width;
    height: 2px;
    margin: $hr-margin;
    background-color: $black-15;
}

// Short hr (aka "chip")
hr.chip {
    margin-top: 0;
    width: $chip-width;
}

// Inline "chip"
hr.chip--inline {
    display: inline-block;
    margin-right: $chip-width / 2;
}